exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-generic-privacy-policy-js": () => import("./../../../src/pages/generic/privacy-policy.js" /* webpackChunkName: "component---src-pages-generic-privacy-policy-js" */),
  "component---src-pages-generic-terms-of-use-js": () => import("./../../../src/pages/generic/terms-of-use.js" /* webpackChunkName: "component---src-pages-generic-terms-of-use-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-internal-preview-js": () => import("./../../../src/pages/internal/preview.js" /* webpackChunkName: "component---src-pages-internal-preview-js" */),
  "component---src-pages-redirect-js": () => import("./../../../src/pages/redirect.js" /* webpackChunkName: "component---src-pages-redirect-js" */),
  "component---src-pages-tracking-js": () => import("./../../../src/pages/tracking.js" /* webpackChunkName: "component---src-pages-tracking-js" */),
  "component---src-templates-post-premium-js": () => import("./../../../src/templates/post-premium.js" /* webpackChunkName: "component---src-templates-post-premium-js" */),
  "component---src-templates-quiz-js": () => import("./../../../src/templates/quiz.js" /* webpackChunkName: "component---src-templates-quiz-js" */)
}

